const SUBJECTS = [
  { subject: "AERO", description: "Aerospace Engineering" },
  { subject: "AGB", description: "Agribusiness" },
  { subject: "AGC", description: "Agricultural Communication" },
  { subject: "AGED", description: "Agricultural Education" },
  { subject: "AG", description: "Agriculture" },
  { subject: "ASCI", description: "Animal Science" },
  { subject: "ANT", description: "Anthropology" },
  { subject: "ARCE", description: "Architectural Engineering" },
  { subject: "ARCH", description: "Architecture" },
  { subject: "ART", description: "Art" },
  { subject: "ASTR", description: "Astronomy" },
  { subject: "BRAE", description: "BioResource & Agricultural Eng" },
  { subject: "BIO", description: "Biology" },
  { subject: "BMED", description: "Biomedical Engineering" },
  { subject: "BOT", description: "Botany" },
  { subject: "BUS", description: "Business" },
  { subject: "CHEM", description: "Chemistry" },
  { subject: "CD", description: "Child Development" },
  { subject: "CHIN", description: "Chinese" },
  { subject: "CRP", description: "City and Regional Planning" },
  { subject: "CE", description: "Civil Engineering" },
  { subject: "CLA", description: "College of Liberal Arts" },
  { subject: "COMS", description: "Communication Studies" },
  { subject: "CPE", description: "Computer Engineering" },
  { subject: "CSC", description: "Computer Science" },
  { subject: "CM", description: "Construction Management" },
  { subject: "DSCI", description: "Dairy Science" },
  { subject: "DANC", description: "Dance" },
  { subject: "DATA", description: "Data Science" },
  { subject: "ERSC", description: "Earth Science" },
  { subject: "ECON", description: "Economics" },
  { subject: "EDUC", description: "Education" },
  { subject: "EE", description: "Electrical Engineering" },
  { subject: "ENGR", description: "Engineering" },
  { subject: "ENGL", description: "English" },
  { subject: "EDES", description: "Environmental Design" },
  { subject: "ENVE", description: "Environmental Engineering" },
  { subject: "ESCI", description: "Environmental Sciences" },
  { subject: "ES", description: "Ethnic Studies" },
  { subject: "FSN", description: "Food Science and Nutrition" },
  { subject: "FR", description: "French" },
  { subject: "GEOG", description: "Geography" },
  { subject: "GEOL", description: "Geology" },
  { subject: "GER", description: "German" },
  { subject: "GSB", description: "Graduate Studies-Business" },
  { subject: "GRC", description: "Graphic Communication" },
  { subject: "HLTH", description: "Health" },
  { subject: "HIST", description: "History" },
  { subject: "HNRS", description: "Honors" },
  { subject: "HNRC", description: "Honors Contract" },
  { subject: "IME", description: "Industrial & Manufacturing Eng" },
  { subject: "ITP", description: "Industrial Tech and Packaging" },
  { subject: "PEW", description: "Intercollegiate Athl-Women" },
  { subject: "PEM", description: "Intercollegiate Athletics-Men" },
  { subject: "ISLA", description: "Interdisc Stds in Liberal Arts" },
  { subject: "ITAL", description: "Italian" },
  { subject: "JPNS", description: "Japanese" },
  { subject: "JOUR", description: "Journalism" },
  { subject: "KINE", description: "Kinesiology" },
  { subject: "LA", description: "Landscape Architecture" },
  { subject: "LAES", description: "Liberal Arts and Engr Studies" },
  { subject: "LS", description: "Liberal Studies" },
  { subject: "MSCI", description: "Marine Science" },
  { subject: "MATE", description: "Materials Engineering" },
  { subject: "MATH", description: "Mathematics" },
  { subject: "ME", description: "Mechanical Engineering" },
  { subject: "MCRO", description: "Microbiology" },
  { subject: "MSL", description: "Military Science Leadership" },
  { subject: "MU", description: "Music" },
  { subject: "NR", description: "Natural Resources" },
  { subject: "PHIL", description: "Philosophy" },
  { subject: "PSC", description: "Physical Sciences" },
  { subject: "PHYS", description: "Physics" },
  { subject: "PLSC", description: "Plant Science" },
  { subject: "POLS", description: "Political Science" },
  { subject: "PSY", description: "Psychology" },
  { subject: "RPTA", description: "Rec, Parks & Tourism Admin" },
  { subject: "RELS", description: "Religious Studies" },
  { subject: "SCM", description: "Science and Mathematics" },
  { subject: "SOC", description: "Sociology" },
  { subject: "SS", description: "Soil Science" },
  { subject: "SPAN", description: "Spanish" },
  { subject: "SPED", description: "Special Education" },
  { subject: "STAT", description: "Statistics" },
  { subject: "TH", description: "Theatre" },
  { subject: "UNIV", description: "University Studies" },
  { subject: "WVIT", description: "Wine and Viticulture" },
  { subject: "WGQS", description: "Women's Gender & Queer Studies" },
  { subject: "WLC", description: "World Languages and Cultures" },
];

export default SUBJECTS;
